export class ScheduledEvent {

  session: String;
  possibleDate: String;
  eventType: String;
  itzName: String;
  creationUser: String;
  label: String;
  purpose: String;
  status: String;
  highlighted: boolean;
  participation: String;
  valitatedCount: number;
  refusedCount: number;
  pendingCount: number;

  constructor() {
    this.session = "";
    this.possibleDate = "";
    this.eventType = "";
    this.creationUser = "" ;
    this.itzName = "";
    this.label = "";
    this.purpose = "";
    this.highlighted = false;
    this.valitatedCount = 0;
    this.refusedCount = 0;
    this.pendingCount = 0;
  }
}
