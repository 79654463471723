import { Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'firstLetterPipe'
})

export class FirstLetterPipe implements PipeTransform {
    
    transform(userLogin: string):  String {
       
        return '' + userLogin.charAt(0).toLowerCase();
    }
}