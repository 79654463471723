import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToolsBoxService} from '../../Services/tools.service';


@Component({
  selector: 'tutorials',
  templateUrl: 'tutorials.component.html',

})

export class Tutorials implements OnInit {

  seeLeave: boolean = true;
  seePassword : boolean;
  seeAddAvatar: boolean;
  seeDelAvatar: boolean;
  seeSocialEvent: boolean;

  constructor(private toolsBoxService: ToolsBoxService,
              private router: Router) {

  }

  ngOnInit() {
    this.toolsBoxService.changeHeaderName("Tutorials");
  }

  addLeave(){
    this.seeLeave = true;
    this.seePassword = false;
    this.seeAddAvatar = false;
    this.seeDelAvatar = false;``
    this.seeSocialEvent = false;
  }

  modifyPassword(){
    this.seeLeave = false;
    this.seePassword = true;
    this.seeAddAvatar = false;
    this.seeDelAvatar = false;``
    this.seeSocialEvent = false;
  }

  addAvatar(){
    this.seeLeave = false;
    this.seePassword = false;
    this.seeAddAvatar = true;
    this.seeDelAvatar = false;``
    this.seeSocialEvent = false;
  }

  delAvatar(){
    this.seeLeave = false;
    this.seePassword = false;
    this.seeAddAvatar = false;
    this.seeDelAvatar = true;``
    this.seeSocialEvent = false;
  }

  socialEvent(){
    this.seeLeave = false;
    this.seePassword = false;
    this.seeAddAvatar = false;
    this.seeDelAvatar = false;``
    this.seeSocialEvent = true;
  }

}
