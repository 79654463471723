import {
  Component, Input, Output, OnInit, ElementRef, EventEmitter, HostListener,
  AfterContentInit
} from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
    selector: 'selectbox',
    templateUrl: 'selectbox.component.html',
    animations: [
        trigger('displaySelectBox', [
          state('closed', style({
            opacity: 0,
            transform: 'scale(.9)'
          })),
          state('open', style({
            opacity: 1,
            transform: 'scale(1)'
          })),
          transition('closed => open', animate('0.08s ease-in')),
          transition('open => closed', animate('0.12s ease-out'))
        ])
    ]
})
export class SelectBoxComponent implements OnInit, AfterContentInit {

    @Input('items') items: String[];
    @Input('value') selectedItem: String;
    @Input('position') position: String;

    @Output() onChange: EventEmitter<any> = new EventEmitter();

    top: String;
    bottom: String;

    state: String;

    constructor(private elRef: ElementRef) {
        this.state = 'closed';
    }

    ngOnInit() {
        if(this.position == 'bottom') {
            this.top = 'inherit';
            this.bottom = '-26px';
        }

        document.body.className += 'select-box-open';
    }

    ngAfterContentInit() {

      /* Test EKA */
      this.items.map((element, index) => {
        if(element === this.selectedItem) {
          /* 5 elements are displayed max */
          /* Position element on top, bottom or center based on parameter */
          if(this.position == null || this.position == 'center') {
            this.elRef.nativeElement.querySelector('ul').scrollTop = 52 * (index - 2);
          } else if(this.position == 'top') {
            this.elRef.nativeElement.querySelector('ul').scrollTop = 52 * index;
          } else if(this.position == 'bottom') {
            this.elRef.nativeElement.querySelector('ul').scrollTop = 52 * (index - 4);
          }
        }
      })
        /* Let scroll to the selected element */
        // for (var index = 0; index <this.items.length; index++) {
        //     var element = this.items[index];
        //
        //     if(element === this.selectedItem) {
        //         /* 5 elements are displayed max */
        //         /* Position element on top, bottom or center based on parameter */
        //         if(this.position == null || this.position == 'center') {
        //             this.elRef.nativeElement.querySelector('ul').scrollTop = 52 * (index - 2);
        //         } else if(this.position == 'top') {
        //             this.elRef.nativeElement.querySelector('ul').scrollTop = 52 * index;
        //         } else if(this.position == 'bottom') {
        //             this.elRef.nativeElement.querySelector('ul').scrollTop = 52 * (index - 4);
        //         }
        //
        //         break;
        //     }
//        }

        this.state = 'open';
    }

    /* Select the item and close the box */
    selectItem(item: String) {
        this.selectedItem = item;
        // EKA: Quickfix: After Angular update, no event is triggered
        this.onChange.emit(item);
        this.hideSelectBox(null);
    }

    /* Hide the select box with animation and listen for outside click */
    @HostListener('document:click', ['$event.target'])
    hideSelectBox(targetElement: any) {
        const clickedInside = this.elRef.nativeElement.parentElement.contains(targetElement);
        if (!clickedInside) {
            this.state = 'closed';
            document.body.classList.remove('select-box-open');
        }
    }

    /* Callback when the animation (fade in or fade out) is done */
    animationDone() {
        if(this.state == 'closed') this.onChange.emit([this.selectedItem]);
    }
}
