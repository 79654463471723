import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  search<T extends { userName: string, userFirstName: string, visible: boolean }>(items: T[], filter: string): T[] {
    if (filter) {
      let filterArr = filter.trim().split(' ');

      items.forEach(item => {
        let visible = true;
        for (let index in filterArr) {
          let criteria = filterArr[index].trim().toUpperCase();
          visible = visible && (item.userName.toUpperCase().indexOf(criteria) !== -1 || item.userFirstName.toUpperCase().indexOf(criteria) !== -1);
        }
        item.visible = visible;
      });

      return items;
    }
    return items;
  }
}
