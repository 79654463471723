import {User} from "../user.component";

export class TechRadarDataHolder {
  user: User;
  skills: {id: number, label: string, quadrant: string, level: string, needTraining: boolean} [];
  techRadarData: {label: string, quadrant: string, ring: number, moved: number}[];

  constructor(messageJSON: any) {
    this.user = messageJSON.user;
    this.skills = messageJSON.skills;
    this.techRadarData = messageJSON.techRadarData;
  }
}

export class SkillData {
  id: number;
  label: string;
  quadrant: string
  level: string
  needTraining: boolean
}

export class BlipsData {
  label: string;
  quadrant: string;
  ring: number;
  moved: number;
}

export class StatData {
  skills: SkillData;
  count: number;

  constructor(messageJSON: any) {
    this.skills = messageJSON.skills;
    this.count = messageJSON.count;
  }
}
