import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { ToolsBoxService } from '../../Services/tools.service';

@Component({
    selector: 'error',
    templateUrl: 'error.component.html'
})
export class ErrorComponent implements OnInit {

    errorMessage: String;

    constructor(private toolsBoxService: ToolsBoxService, private route: ActivatedRoute,
    private router: Router) { }

    ngOnInit() {
        this.toolsBoxService.changeHeaderName("Error");
        let subResult = this.route.params.subscribe(params => {
                this.errorMessage  = params['errMessage'];
        });
    }

    ngAfterViewInit() {
        this.toolsBoxService.changeHeaderName("An error occured");
    }

}
