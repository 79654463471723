import { Pipe, PipeTransform} from '@angular/core';
import { User } from '../Models/user.component';

@Pipe({
    name: 'filter'
})

export class FilterPipe implements PipeTransform {

    transform(list : User[], filter: string, displayInactiveUser: boolean = false): any {
        let myList = list?.filter(user => {
          switch (displayInactiveUser) {
            case true:
            return true;
            case false :
              return user.userStatus === "ACTIVE"
          }
        })
        if (filter && myList) {
            return myList.filter((user) => {
                return (user.userFirstName.toUpperCase().startsWith(filter.toUpperCase()) ||
                user.userName.toUpperCase().startsWith(filter.toUpperCase())
            );
          });
        } else {
            return myList;
        }
    }
}
