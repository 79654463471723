import { Injectable } from '@angular/core';
import { CallApi, HTTP_COMMAND } from './callApi';
import { ToolsBoxService } from './tools.service';
import { PlanningItem } from '../Models/planningitem.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private callAPI: CallApi, private toolsBox: ToolsBoxService) {}

  getSchedule = (): Observable<PlanningItem[]> => {

    let serviceURi = '/social-events/schedule/';
    return this.callAPI.call(HTTP_COMMAND.GET, serviceURi);
  }
}
