// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn.btn-raised:not(.btn-link):hover,
.btn:not(.btn-raised):not(.btn-link):hover {
  background-color: #0088cf;
	color: rgba(255,255,255, 0.7);
}

.btn.btn-raised:not(.btn-link).active,
.btn:not(.btn-raised):not(.btn-link).active {
  background-color: #0077B5;
	color: #fff;
  border-radius: 5px;
}

.btn:not(.btn-raised):not(.btn-link).not-editable:hover {
	background: none;
	cursor: default;
}

.release-template {

  h2 {
    font-size : 16px !important;
  }

  li {
    list-style-type: circle;
  }
}

h2 {
  font-size : 16px !important;
}

ul.a {
  list-style-type: circle;
}
`, "",{"version":3,"sources":["webpack://./src/app/Components/ReleaseNote/releasenote.component.css"],"names":[],"mappings":"AAAA;;EAEE,yBAAyB;CAC1B,6BAA6B;AAC9B;;AAEA;;EAEE,yBAAyB;CAC1B,WAAW;EACV,kBAAkB;AACpB;;AAEA;CACC,gBAAgB;CAChB,eAAe;AAChB;;AAEA;;EAEE;IACE,2BAA2B;EAC7B;;EAEA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".btn.btn-raised:not(.btn-link):hover,\n.btn:not(.btn-raised):not(.btn-link):hover {\n  background-color: #0088cf;\n\tcolor: rgba(255,255,255, 0.7);\n}\n\n.btn.btn-raised:not(.btn-link).active,\n.btn:not(.btn-raised):not(.btn-link).active {\n  background-color: #0077B5;\n\tcolor: #fff;\n  border-radius: 5px;\n}\n\n.btn:not(.btn-raised):not(.btn-link).not-editable:hover {\n\tbackground: none;\n\tcursor: default;\n}\n\n.release-template {\n\n  h2 {\n    font-size : 16px !important;\n  }\n\n  li {\n    list-style-type: circle;\n  }\n}\n\nh2 {\n  font-size : 16px !important;\n}\n\nul.a {\n  list-style-type: circle;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
