import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../Services/user.service';
import { ToolsBoxService } from '../../Services/tools.service';
import { FirstLetterPipe } from '../../Pipes/firstletter.pipe';
import { User } from '../../Models/user.component';
import { map, catchError } from 'rxjs/operators'
import {HttpErrorResponse} from "@angular/common/http";
import {EMPTY, Observable} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import { SearchService } from '../../Services/search.service';

/**
 * Will contact the server in order to retrieve user list with contact information
 */
@Component({
    selector: 'directory',
    templateUrl: 'directory.component.html',
    styleUrls: ['directory.component.css']
})
export class DirectoryComponent implements OnInit {

    userList: User[];
    userListObservable$: Observable<User[]>;
    AvatarBackground: String;
    userFilter: string;

    showResetModal: boolean = false;
    userToUpdate: User;

    constructor(private userService: UserService,
                private searchService: SearchService,
                private toolsBoxService: ToolsBoxService,
                private sanitizer: DomSanitizer,
                private router: Router) {
    }

    ngOnInit() {
        this.toolsBoxService.changeHeaderName("Directory");
        this.toolsBoxService.tellChildContainsTabs(false);
        this.initUserList();
    }

    initUserList = () => {
      this.toolsBoxService.tellActionIsInProgress(true);
      this.userService.getUserList().subscribe(
        (list: User[]) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.userList = list;
        },
        (err) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.router.navigate(["Error", {errMessage: err.error}]);
        }
      );
    }

    getStyle = (user: User) : any => {
        let firstLetterPipe = new FirstLetterPipe();
        let classElement =  {
          'avatar-icon': true,
          'available' : (user.availability!=='OFF'),
          'off': (user.availability==='OFF')
        };
        if (!user.avatar) {
          classElement = Object.assign(classElement, {['user-first-initial-' + firstLetterPipe.transform(user.userName)]: true});
        }
        return classElement;
    }

    //Reset button is only displayed if the current user has ADMIN profile
    resetUserPwd = (user: User): void => {
      if (this.toolsBoxService.getStoredUserProfile() == 'ADMIN') {
        this.showResetModal = true;
        this.userToUpdate = user;
      }
    }

    search = (): void => {
      this.userList = this.searchService.search(this.userList, this.userFilter);
    }

    resetFilter = (): void => {
        this.userFilter = "";

        this.search();
    }

  generateSkypeIdentifier = (userSkypeAccount: String) => {
    return this.sanitizer.bypassSecurityTrustUrl("skype:" + userSkypeAccount);
  }
}
