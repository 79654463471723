import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { User } from "../../../../Models/user.component";

@Component({
  selector: 'user-information-tab',
  templateUrl: './user-information-tab.component.html',
  styleUrls: ['./user-information-tab.component.css']
})
export class UserInformationTabComponent implements OnChanges, OnInit {

  @Input() user: User;  // Input property to receive user data
  @Output() userUpdated: EventEmitter<User> = new EventEmitter<User>();  // Output event emitter to send updated user data
  @Output() resetPassword: EventEmitter<void> = new EventEmitter<void>();  // Output event emitter to signal password reset

  form: FormGroup; // Reactive form group to handle user form

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['user'] && this.user) {
      this.updateFormValues();  // Update form values when user input changes
    }
  }

  initializeForm() {
    this.form = this.fb.group({
      isAdmin: [false, Validators.required],
      userName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userLogin: ['', Validators.required],
      userMailAddress: ['', Validators.required],
      userRecordID: ['', Validators.required],
      userClientName: ['', Validators.required],
      isBlocked: [false, Validators.required]
    });

    if (this.user) {
      // Initialize form with user data if available
      this.updateFormValues();
    }
  }

  updateFormValues() {
    if (this.form) {
      this.form.patchValue({
        isAdmin: this.user.userProfile === 'ADMIN',
        userName: this.user.userName,
        firstName: this.user.userFirstName,
        lastName: this.user.userName,
        userLogin: this.user.userLogin,
        userMailAddress: this.user.userMailAddress,
        userRecordID: this.user.recordId,
        userClientName: this.user.clientName,
        isBlocked: this.user.userStatus === 'INACTIVE'
      });
    }
  }

  switchUserProfile() {
    if (this.user) {
      this.form.controls['isAdmin'].patchValue(!this.form.controls['isAdmin'].value);
      this.updateUser();
    }
  }

  switchUserStatus() {
    if (this.user) {
      this.form.controls['isBlocked'].patchValue(!this.form.controls['isBlocked'].value);
      this.updateUser();
    }
  }

  itzInitialisation() {
    if (this.form) {
      const firstName = this.form.controls['firstName'].value || '';
      const lastName = this.form.controls['lastName'].value || '';
      this.form.controls['userMailAddress'].patchValue(`${firstName.toLowerCase()}.${lastName.toLowerCase()}@xpertiz.lu`);
      this.form.controls['userLogin'].patchValue(`${firstName.toLowerCase()}.${lastName.toLowerCase()}`);
      this.updateUser();
    }
  }

  resetUserPwd() {
    this.resetPassword.emit();
  }

  updateUser() {
    if (this.user && this.form) {
      this.user.userFirstName = this.form.controls['firstName'].value;
      this.user.userName = this.form.controls['lastName'].value;
      this.user.userLogin = this.form.controls['userLogin'].value;
      this.user.userMailAddress = this.form.controls['userMailAddress'].value;
      this.user.clientName = this.form.controls['userClientName'].value || 'BENCH';
      this.user.recordId=this.form.controls['userRecordID'].value || '';
      this.user.userProfile = this.form.controls['isAdmin'].value ? 'ADMIN' : 'EMPLOYEE';
      this.user.userStatus = this.form.controls['isBlocked'].value ? 'INACTIVE' : 'ACTIVE';

      // Check if balances is null and initialize if necessary
      if (this.user.balances == null) {
        this.user.balances = [];
      }

      this.userUpdated.emit(this.user);
    }
  }
}
