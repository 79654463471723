import {Component, inject, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {ToolsBoxService} from '../../Services/tools.service';
import {UserService} from '../../Services/user.service';
import {Balance} from "../../Models/balance.component";
import { User } from '../../Models/user.component';
import { SearchService } from '../../Services/search.service';

@Component({
  selector: 'usersAdministration',
  templateUrl: 'usersAdministration.component.html',
  styleUrls: ['usersAdministration.component.css']
})

export class UsersAdministration implements OnInit {

  showResetModal: boolean = false; // Flag to show/hide the reset modal
  notifMessage: String; // Notification message
  notifType: String; // Notification type
  isInfoPanelOpen: boolean = true;

  // Itz list displayed
  $itzList: User[] = [];
  displayInactiveUser: boolean = false; // Flag to show/hide inactive users

  // Data that will be edited
  selectedUser: User = new User();

  selectedUserBalance: Balance[] = []; // Balances of the selected user

  // Filter
  itzFilter: string = "";

  private toolsBoxService: ToolsBoxService = inject(ToolsBoxService);
  private userService: UserService = inject(UserService);
  private router: Router = inject(Router);
  private searchService: SearchService = inject(SearchService);

  ngOnInit() {
    this.toolsBoxService.changeHeaderName("Users Administration");
    this.initItzList();
  }

  // Initialize the Itz list
  initItzList = () => {
    this.toolsBoxService.tellActionIsInProgress(true);
    this.userService.getItzList().subscribe({
      next: (list: User[]) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.$itzList = list;
      },
      error: err => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", { errMessage: err.error }]);
      }
    });
  }

  // Send new user or update existing user
  updateOrNewUser = () => {
    if (this.checkUpdate() && this.selectedUser) {
      this.toolsBoxService.tellActionIsInProgress(true);

      this.userService.createOrUpdateITz(this.selectedUser).subscribe({
        next: data => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.notifType = 'info';
          this.notifMessage = data;
          this.initItzList();
        },
        error: err => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.router.navigate(["Error", { errMessage: err.error }]);
        }
      })
    }
  }

  // Check the update form for validity
  checkUpdate = () => {
    this.notifType = 'error'
    if (!this.selectedUser.userFirstName) {
      this.notifMessage = 'First name is mandatory';
    } else if (!this.selectedUser.userName) {
      this.notifMessage = 'Name is mandatory';
    } else if (!this.selectedUser.userLogin) {
      this.notifMessage = 'Login is mandatory';
    } else if (this.isNotValidLoginFormat()) {
      this.notifMessage = 'Login is not well formatted';
    } else if (!this.selectedUser.userMailAddress) {
      this.notifMessage = 'Mail address is mandatory';
    } else if (this.isNotValidMailFormat()) {
      this.notifMessage = 'Mail address format is incorrect';
    }
    return (this.notifMessage == null);
  }

  // Validate login format
  isNotValidLoginFormat = () => {
    var LOGIN_REGEXP = /^[\p{Ll}0-9!#$%&'*+\/=?^_`{|}~.-]+$/u;

    let result = (this.selectedUser.userLogin != "" && (!LOGIN_REGEXP.test(this.selectedUser.userLogin.toLowerCase())))
    return result;
  }

  // Validate email format
  isNotValidMailFormat = () => {
    var EMAIL_REGEXP = /^[\p{Ll}0-9!#$%&'*+\/=?^_`{|}~.-]+@[\p{Ll}0-9]([\p{Ll}0-9-]*[\p{Ll}0-9])?(\.[\p{Ll}0-9]([\p{Ll}0-9-]*[\p{Ll}0-9])?)*$/u;

    return (this.selectedUser.userMailAddress != "" && (this.selectedUser.userMailAddress.length <= 5 || !EMAIL_REGEXP.test(this.selectedUser.userMailAddress.toLowerCase())))
  }

  // Initialize the input form with user information
  initInformationInputForm = (itz?: User) => {
    this.selectedUser = Object.assign({}, itz);
    this.selectedUserBalance = Object.assign([], itz?.balances || []);
    this.isInfoPanelOpen = true;

  }

  // Set background color based on user status
  setBackgroundColor(itz: any): string {
    return itz.userStatus === 'INACTIVE' ? '#ffebee' : 'inherit';
  }

  // Handle user updates from the child component
  onUserUpdated(updatedUser: User) {
    this.selectedUser = updatedUser;
  }

  // Reset the filter
  resetFilter = (): void => {
    this.itzFilter = "";
    this.search();
  }

  shouldHideUser(itz: any): boolean {
    if (this.displayInactiveUser) {
      return false;
    } else {
      return itz.userStatus === 'INACTIVE';
    }
  }

  search = (): void => {
    this.$itzList = this.searchService.search(this.$itzList, this.itzFilter);
  }
}
