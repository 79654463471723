import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormGroup, Validators, UntypedFormBuilder} from '@angular/forms';
import {AuthenticationService} from '../../Services/authentication.service';
import {ToolsBoxService} from '../../Services/tools.service';
import {UserService} from '../../Services/user.service';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";


@Component({
  selector: 'login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent {
  userForm: UntypedFormGroup;
  userConfirmPassword: string;
  userPassword: string;

  version: String = '';
  backEndVersion$: Observable<String>;

  errorCode: number = 0;
  errorMessage: String;

  firstLogin: boolean = false;

  nbErrors: number;

  constructor(private router: Router, private form: UntypedFormBuilder, private userService: UserService,
              private authService: AuthenticationService, private toolsBoxService: ToolsBoxService) {

    this.nbErrors = 0;

    this.version = this.toolsBoxService.getVersion();

    this.backEndVersion$ = this.toolsBoxService.fireUBackVersionUpdated
      .pipe(map((backEndVersion: String) => backEndVersion));

    this.userForm = form.group({
      userName: [this.toolsBoxService.getStoredUserLogin(), Validators.required],
      userPassword: ['', Validators.required],
      userConfirmPassword: ['', Validators.required]
    });

    this.userForm.valueChanges.subscribe(() => {
      if (this.userForm.errors) {
        this.nbErrors = Object.keys(this.userForm.errors).length;
      }
    });
  }

  ngOnInit() {
    this.userForm.value.userName = this.toolsBoxService.getStoredUserLogin();
    if (this.userForm.value.userName) {
      this.errorCode = 401;
      this.errorMessage = 'Your authenticate is not valid anymore';
    }
  }


  onClose(reason: string) {
    this.errorCode = 0;
  }


  initChangePwdRequest(): void {
    this.firstLogin = true;
    (this.userForm.controls['userPassword']).setValue("");
  }

  changePassword(): void {

    if (this.userForm.value.userPassword === '' || this.userForm.value.userPassword != this.userForm.value.userConfirmPassword) {
      return;
    }

    //Call the authenticateService and get an Observable
    this.toolsBoxService.tellActionIsInProgress(true);
    const $returnSubs = this.userService.updateUserPwd(this.userForm.value.userName.trim(), this.userForm.value.userPassword)
      .subscribe({
        next: () => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.router.navigate(['Dashboard']);
          this.firstLogin = false;
          this.errorCode = 0;
          sessionStorage.setItem('user_login', this.userForm.value.userName.trim());
        },
        error: (err) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.firstLogin = false;
          this.errorCode = 500;
          if (err.status) {
            this.errorMessage = err.error;
          } else {
            this.errorMessage = err.message;
          }
        }
      }
    );

  }

  authentication(salt: string): void {

    //Call the authenticateService and get an Observable
    this.toolsBoxService.tellActionIsInProgress(true);
    //var $returnSubs  =  this.authService.authenticate(this.user.userName.trim(),this.user.userPassword)
    var $returnSubs = this.authService.sendCredential(this.userForm.value.userName.trim(), this.userForm.value.userPassword, salt)
      .subscribe(
        (data) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          if (data === 0) {
            this.router.navigate(['Dashboard']);
            this.errorCode = 0;
          } else {
            this.initChangePwdRequest();
          }
        },
        (err) => {
          this.toolsBoxService.tellActionIsInProgress(false);
          this.errorCode = 500;
          if (err.status) {
            this.errorMessage = err.error;
          } else {
            this.errorMessage = err.message;
          }

        });
  }

  /**
   * Will retrieve the salt from the backend and will trigger the authentication
   */
  getSalt(): void {

    //Call the authenticateService and get an Observable
    this.toolsBoxService.tellActionIsInProgress(true);
    var $returnSubs = this.authService.getSalt(this.userForm.value.userName.trim())
      .subscribe(
        (data) => {
          this.authentication(data);
        },
        (err) => {
          this.authentication("");
        });
  }
}
