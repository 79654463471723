// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn.btn-raised {
  width: 10%;
  text-align: center !important;
}

.btn.btn-raised.primary {
  background-color: #0077B5;
  color: #ffffff;
}

.btn.btn-raised.primary:hover, .btn.btn-raised.primary:focus {
	background-color: #3788d8;
}

/* Settings */
.settings-div, .settings-div > div {
	display: flex;
	align-items: center;
}

.settings-div > button {
  justify-content: center;
}

.settings-div img.avatar-icon {
	border-radius: 50%;
}
.settings-div .avatar-icon+label, button {
	margin-left: 7px;
	margin-right: 7px;
	font-size: 14px;
  font-weight: 500;
}
.settings-div button i.material-icons {
	font-size: 3em;
	top: 4px;
	cursor: pointer;
}

#singlebutton {
  margin-right: 10px;
}

/* Small devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .btn.btn-raised {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/Components/Settings/settings.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA,aAAa;AACb;CACC,aAAa;CACb,mBAAmB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;CACC,kBAAkB;AACnB;AACA;CACC,gBAAgB;CAChB,iBAAiB;CACjB,eAAe;EACd,gBAAgB;AAClB;AACA;CACC,cAAc;CACd,QAAQ;CACR,eAAe;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA,0CAA0C;AAC1C;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".btn.btn-raised {\n  width: 10%;\n  text-align: center !important;\n}\n\n.btn.btn-raised.primary {\n  background-color: #0077B5;\n  color: #ffffff;\n}\n\n.btn.btn-raised.primary:hover, .btn.btn-raised.primary:focus {\n\tbackground-color: #3788d8;\n}\n\n/* Settings */\n.settings-div, .settings-div > div {\n\tdisplay: flex;\n\talign-items: center;\n}\n\n.settings-div > button {\n  justify-content: center;\n}\n\n.settings-div img.avatar-icon {\n\tborder-radius: 50%;\n}\n.settings-div .avatar-icon+label, button {\n\tmargin-left: 7px;\n\tmargin-right: 7px;\n\tfont-size: 14px;\n  font-weight: 500;\n}\n.settings-div button i.material-icons {\n\tfont-size: 3em;\n\ttop: 4px;\n\tcursor: pointer;\n}\n\n#singlebutton {\n  margin-right: 10px;\n}\n\n/* Small devices (tablets, 768px and up) */\n@media (max-width: 768px) {\n  .btn.btn-raised {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
