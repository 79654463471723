// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dashboard #d-balance {
	background: #fff;
	padding-left: 70px;
}
#dashboard #d-balance > div {
	padding: 12px 12px 12px 0;
	position: relative;
}
#dashboard #d-balance > div:first-child {
	margin-bottom: 10px;
}
#dashboard #d-balance > div:first-child:before {
	content: '\\E85C';
    font-family: 'Material Icons';
    position: absolute;
    color: #23C7AF;
    left: -62px;
    font-size: 56px;
    top: 5px;
}
#dashboard #d-balance > div+div {
	border-top: 1px solid rgba(0,0,0,.14);
}
#dashboard #d-balance > div+div .row {
	margin-bottom: 5px;
}
#dashboard #d-balance #balance-value {
	position: absolute;
    right: 12px;
    bottom: -6px;
	font-size: 34px;
	color: #23C7AF
}

.row {
  color: #1a252f;
}

.scrollDiv {
  height: 87vh;
  overflow-x: auto;
}

.off-informations {
  margin-left: 15%;
}

/** Buttons Success - Warn **/
.btn.btn-success-custom {
  background-color: #27ae60;
}

.btn:not(.btn-raised):not(.btn-link):hover.btn-success-custom {
	background-color: #2CC36B;
	color: rgba(255,255,255,.7)
}

.btn.btn-danger-custom {
  background-color: #e74c3c;
  color: rgba(255,255,255, 0.84);
}

.btn:not(.btn-raised):not(.btn-link):hover.btn-danger-custom {
	background-color: #EA6153;
	color: rgba(255,255,255,.7)
}
`, "",{"version":3,"sources":["webpack://./src/app/Components/Dashboard/dashboard.component.css"],"names":[],"mappings":"AAAA;CACC,gBAAgB;CAChB,kBAAkB;AACnB;AACA;CACC,yBAAyB;CACzB,kBAAkB;AACnB;AACA;CACC,mBAAmB;AACpB;AACA;CACC,gBAAgB;IACb,6BAA6B;IAC7B,kBAAkB;IAClB,cAAc;IACd,WAAW;IACX,eAAe;IACf,QAAQ;AACZ;AACA;CACC,qCAAqC;AACtC;AACA;CACC,kBAAkB;AACnB;AACA;CACC,kBAAkB;IACf,WAAW;IACX,YAAY;CACf,eAAe;CACf;AACD;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA,6BAA6B;AAC7B;EACE,yBAAyB;AAC3B;;AAEA;CACC,yBAAyB;CACzB;AACD;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;CACC,yBAAyB;CACzB;AACD","sourcesContent":["#dashboard #d-balance {\n\tbackground: #fff;\n\tpadding-left: 70px;\n}\n#dashboard #d-balance > div {\n\tpadding: 12px 12px 12px 0;\n\tposition: relative;\n}\n#dashboard #d-balance > div:first-child {\n\tmargin-bottom: 10px;\n}\n#dashboard #d-balance > div:first-child:before {\n\tcontent: '\\E85C';\n    font-family: 'Material Icons';\n    position: absolute;\n    color: #23C7AF;\n    left: -62px;\n    font-size: 56px;\n    top: 5px;\n}\n#dashboard #d-balance > div+div {\n\tborder-top: 1px solid rgba(0,0,0,.14);\n}\n#dashboard #d-balance > div+div .row {\n\tmargin-bottom: 5px;\n}\n#dashboard #d-balance #balance-value {\n\tposition: absolute;\n    right: 12px;\n    bottom: -6px;\n\tfont-size: 34px;\n\tcolor: #23C7AF\n}\n\n.row {\n  color: #1a252f;\n}\n\n.scrollDiv {\n  height: 87vh;\n  overflow-x: auto;\n}\n\n.off-informations {\n  margin-left: 15%;\n}\n\n/** Buttons Success - Warn **/\n.btn.btn-success-custom {\n  background-color: #27ae60;\n}\n\n.btn:not(.btn-raised):not(.btn-link):hover.btn-success-custom {\n\tbackground-color: #2CC36B;\n\tcolor: rgba(255,255,255,.7)\n}\n\n.btn.btn-danger-custom {\n  background-color: #e74c3c;\n  color: rgba(255,255,255, 0.84);\n}\n\n.btn:not(.btn-raised):not(.btn-link):hover.btn-danger-custom {\n\tbackground-color: #EA6153;\n\tcolor: rgba(255,255,255,.7)\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
