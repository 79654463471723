import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {BlipsData} from "../../Models/Holders/techRadarDataHolder.component";

declare function radar_visualization(conf: {}): any;

@Component({
  selector: 'radar',
  template: '<svg id=\"radar\"></svg>'
})
export class Radar implements OnInit, OnChanges {

  @Input('blips') blips: BlipsData[] = [];

  constructor() {
  }

  ngOnInit() {
    this.initRadar();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initRadar();
  }

  initRadar = () => {
    let radarConfig = {
      svg_id: "radar",
      width: 1000,
      height: 1000,
      colors: {
        background: "#fff",
        grid: "#bbb",
        inactive: "#ddd"
      },
      title: "Itz Tech Radar",
      quadrants: [
        {name: "Platforms"},
        {name: "Methodologies"},
        {name: "Languages and framework"},
        {name: "Tools"}
      ],
      rings: [
        {name: "NOTIONS", color: "#93c47d"},
        {name: "PROEFFICIENT", color: "#93d2c2"},
        {name: "INTERESTED", color: "#fbdb84"}
      ],
      print_layout: true,
      print_legend: false,
      // zoomed_quadrant: 0,
      //ENTRIES
      entries: this.blips
    };
    radar_visualization(radarConfig);
  }
}
