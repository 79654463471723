import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from "@angular/router";
/**
 * The JWT is stored in sessionStorage. By removing the value of "token"
 * the JWT is deleted and the user is considered as logged out
 */
@Component({
  selector: 'logout',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent implements AfterViewInit {
  constructor(private router: Router, private changeDetector: ChangeDetectorRef) { }

  ngAfterViewInit() {
    setTimeout(() => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user_login");
      this.router.navigate(['Login']);
    },1);
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }
}
