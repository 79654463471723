import {CalendarEventHolder} from "./Holders/calendareventholder.component";
import {User} from "./user.component";

export class PlanningItem {
  event: CalendarEventHolder;
  agreeList: User[];
  refuseList: User[];
  pendingList: User[];

  constructor() {
  }
}
