// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-header {
  padding-bottom: 9px;
  margin: 0;
  border-bottom: 1px solid #eeeeee;
}

.avatar-icon {
  margin-right: 20px !important;
  background: #48C2F9 ;
}
`, "",{"version":3,"sources":["webpack://./src/app/Components/Header/header.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;AACtB","sourcesContent":[".page-header {\n  padding-bottom: 9px;\n  margin: 0;\n  border-bottom: 1px solid #eeeeee;\n}\n\n.avatar-icon {\n  margin-right: 20px !important;\n  background: #48C2F9 ;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
