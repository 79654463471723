import {Component, Input, OnInit, HostListener} from '@angular/core';

@Component({
  selector: 'progressbar',
  templateUrl: 'progressbar.component.html'
})
export class ProgressBarComponent implements OnInit {
  constructor() {}

  // Input parameters
  @Input('value') value: number;
  @Input('color') bgColor: String;
  @Input('label') label: String;

  width: String;
  active: boolean = false;

  ngOnInit() {
    this.width = this.value + '%';
  };

  @HostListener('window:mouseup', ['$event'])
  @HostListener('window:mousedown', ['$event'])
  activate(active: boolean) {
    this.active = active;
  }
}
