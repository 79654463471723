//Angular
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from "@angular/common/http";
import {routing} from './app.routes';
import {AppComponent} from './app.component';
import {OverviewComponent} from './Components/Overview/overview.component';
import {InputModalComponent} from './Modals/inputmodal.component';
import {JustificationModalComponent} from './Modals/justificationmodal.component';
import {NotificationModalComponent} from './Modals/notificationmodal.component';
import {ResetPasswordModalComponent} from './Modals/resetpwdmodal.component';
import {HeaderComponent} from './Components/Header/header.component';
import {NavBarComponent} from './Components/NavBar/navbar.component';
import {LoginComponent} from './Components/Login/login.component';
import {LogoutComponent} from './Components/Logout/logout.component';
import {TimeManagement} from './Components/TimeManagement/timemanagement.component';
import {DirectoryComponent} from './Components/Directory/directory.component';
import {ErrorComponent} from './Components/Error/error.component';
import {SettingsComponent} from './Components/Settings/settings.component';
import {DashboardComponent} from './Components/Dashboard/dashboard.component';
import {NotificationComponent} from './Notification/notification.component';
import {TSCheckComponent} from './Components/TSCheck/tscheck.component';
import {ProgressBarComponent} from './ProgressBar/progressbar.component';
import {ReleaseNoteComponent} from './Components/ReleaseNote/releasenote.component';
import {Planning} from './Components/Planning/planning.component';
import {UsersAdministration} from "./Components/UsersAdministration/usersAdministration.component";
import {Tutorials} from "./Components/Tutorials/tutorials.component";
import {SelectBoxComponent} from './SelectBox/selectbox.component';
import {CalendarComponent} from "./Components/CalendarComponent/calendar.component";

//Services
import {AuthenticationService} from './Services/authentication.service';
import {UserService} from './Services/user.service';
import {ToolsBoxService} from './Services/tools.service';
import {ClientService} from './Services/client.service';
import {CallApi} from "./Services/callApi";

//Pipes
import {UserPipe} from './Pipes/users.pipe';
import {InitialsPipe} from './Pipes/initials.pipe';
import {FirstNamePipe} from "./Pipes/firstname.pipe";
import {TrigrammePipe} from './Pipes/trigramme.pipe';
import {FilterPipe} from './Pipes/filter.pipe';
import {FilterClientPipe} from './Pipes/filterClient.pipe';


//Historical
import {ScheduleComponent} from "./Components/Schedule/schedule.component";
import {TechRadarComponent} from "./Components/TechRadarComponent/techRadar.component";
import {SkillSurveyComponent} from "./Components/TechRadarComponent/skill-survey.component";
import { Radar } from './Components/TechRadarComponent/radar';

// @ts-ignore
import {CalendarModule} from "primeng/calendar";
import {SelectButtonModule} from "primeng/selectbutton";
import {InputSwitchModule} from "primeng/inputswitch";
import {TabViewModule} from "primeng/tabview";
import {TabMenuModule} from "primeng/tabmenu";
import {FileUploadModule} from "primeng/fileupload";
import {FullCalendarModule} from "@fullcalendar/angular";
import { ClickOutsideDirective } from './Directives/click-outside.directive';
import { DateFormatPipe } from './Pipes/date-format.pipe';
import {CheckboxModule} from "primeng/checkbox";
import { BalanceTabComponent } from './Components/UsersAdministration/tabs/balance-tab/balance-tab.component';
import { UserInformationTabComponent } from './Components/UsersAdministration/tabs/user-information-tab/user-information-tab.component';
import { CommonModule } from '@angular/common';
import { FollowUsPanelComponent } from './Components/Shared/follow-us-panel/follow-us-panel.component';
import { InfoPanelComponent } from './Components/Shared/info-panel/info-panel.component';
import { SearchBarComponent } from './Components/Shared/search-bar/search-bar.component';
import { EventManagement } from './Components/EventManagement/eventmanagement.component';

@NgModule({
  imports: [BrowserAnimationsModule, BrowserModule, routing, FormsModule, HttpClientModule, ReactiveFormsModule,
    //PrimeNG (Dashboard, InputModal, Overview, TimeManagement)
    CalendarModule, SelectButtonModule, InputSwitchModule, TabViewModule, TabMenuModule, FileUploadModule,
    FullCalendarModule, CheckboxModule,CommonModule

  ],       // module dependencies
  declarations: [
    //Custom Components
    AppComponent, TimeManagement, DirectoryComponent, ErrorComponent, SettingsComponent, DashboardComponent, LogoutComponent,
    NotificationComponent, TSCheckComponent, ProgressBarComponent, ReleaseNoteComponent, EventManagement, Planning, UsersAdministration,
    SelectBoxComponent, Tutorials, ScheduleComponent, TechRadarComponent, SkillSurveyComponent, Radar,
    BalanceTabComponent, UserInformationTabComponent,
    //InputModal
    //ClickOutsideDirective,
    //PrimeNG (Dashboard, InputModal, Overview, TimeManagement)
    // UIChart,
    //Custom directives (TimeManagement)
    OverviewComponent, InputModalComponent, JustificationModalComponent, NotificationModalComponent,
    //Custom directives (AppComponent)
    NavBarComponent, LoginComponent, HeaderComponent, CalendarComponent,
    //Custom directives (Directory)
    ResetPasswordModalComponent,
    //Pipes
    UserPipe, InitialsPipe, TrigrammePipe, FirstNamePipe, FilterPipe, FilterClientPipe, ClickOutsideDirective, DateFormatPipe, FollowUsPanelComponent, InfoPanelComponent, SearchBarComponent],   // components and directives

  bootstrap: [AppComponent],     // root component
  providers: [
    CallApi,
    ToolsBoxService,
    AuthenticationService,
    UserService,
    ClientService
  ] // services
})
export class AppModule {
}
