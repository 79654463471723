// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn.btn-raised, .btn.btn-link{
  background-color: #0077B5;
  color: #ffffff;
}

.btn.btn-raised:hover, .btn.btn-link:hover {
	background-color: #3788d8;
}

/* Select box */
body.select-box-open #body-content {
	overflow: visible;
}

.select-box-container {
	position: relative;
	display: inline-block;
}

.select-box-selector span{
	cursor: pointer;
	position: relative;
  height: auto;
}

.select-box-selector:after {
	content: "\\e5c5";
	font-size: 18px;
	position: relative;
	right: -4px;
	top: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/app/Components/CalendarComponent/calendar.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA,eAAe;AACf;CACC,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;CAClB,qBAAqB;AACtB;;AAEA;CACC,eAAe;CACf,kBAAkB;EACjB,YAAY;AACd;;AAEA;CACC,gBAAgB;CAChB,eAAe;CACf,kBAAkB;CAClB,WAAW;CACX,QAAQ;AACT","sourcesContent":[".btn.btn-raised, .btn.btn-link{\n  background-color: #0077B5;\n  color: #ffffff;\n}\n\n.btn.btn-raised:hover, .btn.btn-link:hover {\n\tbackground-color: #3788d8;\n}\n\n/* Select box */\nbody.select-box-open #body-content {\n\toverflow: visible;\n}\n\n.select-box-container {\n\tposition: relative;\n\tdisplay: inline-block;\n}\n\n.select-box-selector span{\n\tcursor: pointer;\n\tposition: relative;\n  height: auto;\n}\n\n.select-box-selector:after {\n\tcontent: \"\\e5c5\";\n\tfont-size: 18px;\n\tposition: relative;\n\tright: -4px;\n\ttop: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
