// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn.btn-raised, .btn.btn-link{
  background-color: #0077B5;
  color: #ffffff;
}

.btn.btn-raised:not(.btn-link):hover,
.btn:not(.btn-raised):not(.btn-link):hover {
  background-color: #0088cf;
	color: rgba(255,255,255, 0.7);
}

.btn.btn-raised:not(.btn-link).active,
.btn:not(.btn-raised):not(.btn-link).active {
  background-color: #0077B5;
	color: #fff;
  border-radius: 5px;
}

.btn:not(.btn-raised):not(.btn-link).not-editable:hover {
	background: none;
	cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/app/Components/Login/login.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;;EAEE,yBAAyB;CAC1B,6BAA6B;AAC9B;;AAEA;;EAEE,yBAAyB;CAC1B,WAAW;EACV,kBAAkB;AACpB;;AAEA;CACC,gBAAgB;CAChB,eAAe;AAChB","sourcesContent":[".btn.btn-raised, .btn.btn-link{\n  background-color: #0077B5;\n  color: #ffffff;\n}\n\n.btn.btn-raised:not(.btn-link):hover,\n.btn:not(.btn-raised):not(.btn-link):hover {\n  background-color: #0088cf;\n\tcolor: rgba(255,255,255, 0.7);\n}\n\n.btn.btn-raised:not(.btn-link).active,\n.btn:not(.btn-raised):not(.btn-link).active {\n  background-color: #0077B5;\n\tcolor: #fff;\n  border-radius: 5px;\n}\n\n.btn:not(.btn-raised):not(.btn-link).not-editable:hover {\n\tbackground: none;\n\tcursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
