import { Balance } from './balance.component';

/**
 * This object represents an User as it is received from the server
 * TODO : Maybe it could be good to rename this in UserHolder and create a new User object
 * @see Event and EventHolder
 */
export class User {
    id: number = 0;
    userProfile: string = '';
    userLogin: string = '';
    userPassword: string = '';
    userFirstName: string = '';
    userName: string = '';
    userMailAddress: string = '';
    userPhoneNumber: string = '';
    userSlackAccount: string = '';
    userSkypeAccount: string = '';
    userPasswordTmp: boolean = false;
    userSalt: string = '';
    balances: Balance[];
    avatar: string;
    userStatus: string;
    availability: string;
    monthlyTimesheet: boolean = false;
    recordId: string;
    partTime: boolean = false;
    parentalLeave: boolean = false;
    clientName: string;
    effectiveStartDate: Date;
    effectiveEndDate: Date;
    takenDaysOffCount: number;
    totalDaysOffCount: number;

    /* Used for filtering */
    visible: boolean = true;

    constructor(userId: number = 0) {
        this.id = userId;
    }

    equals =  (o: any) : boolean => {
        return this.id === o.id;
    };
}
