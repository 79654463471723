import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-follow-us-panel',
  templateUrl: './follow-us-panel.component.html',
  styleUrls: ['./follow-us-panel.component.css']
})
export class FollowUsPanelComponent {
  @Input() panelId: string;
  @Input() heading: string;
  @Input() link: string;
  @Input() linkText: string = 'Let\'s go';
}
