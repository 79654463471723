import { Component, OnInit, Input, Output, HostListener, EventEmitter} from '@angular/core';
import { CalendarEventHolder  } from '../Models/Holders/calendareventholder.component';

@Component({
    selector: 'justification-modal',
	templateUrl: 'justificationmodal.component.html'
})
export class JustificationModalComponent implements OnInit {

	@Input('currentEvent') currentEvent: CalendarEventHolder = new CalendarEventHolder();
  @Input('title') modalTitle: String;
  @Input('open') justificationModalOpened: boolean;

  // Output event
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Output() closeModal: EventEmitter<any> = new EventEmitter();

	@HostListener('keydown.esc', ['$event'])
	close(resultToSend: String) {
		this.justificationModalOpened = false;
    this.openChange.emit(this.justificationModalOpened);
		this.closeModal.next(resultToSend);
	}

    ngOnInit() {}
}
