import {Component, OnInit} from '@angular/core';
import {ToolsBoxService} from "../../Services/tools.service";

@Component({
  selector: 'releaseNote',
  templateUrl: 'releasenote.component.html',
  styleUrls: ['releasenote.component.css']
})
export class ReleaseNoteComponent implements OnInit {

  backEndReleaseNote: string;
  displayFrontRelease: boolean= true;
  apiDocAddress: string;

  constructor(private toolsBoxService: ToolsBoxService) {
    this.backEndReleaseNote = this.toolsBoxService.backEndReleaseNote;
    this.apiDocAddress = this.toolsBoxService.getServerAddress().replace('api', 'swagger-ui/');
  }

  ngOnInit() {
   this.toolsBoxService.changeHeaderName("Release Note");
  }

}
