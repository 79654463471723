import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent {

  @Input() placeholder: string = 'Search';
  @Input() filterParameter: string = '';
  @Output() filterParameterChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() resetFilterEvent: EventEmitter<void> = new EventEmitter<void>();

  search() {
    this.searchEvent.emit();
    this.filterParameterChange.emit(this.filterParameter);
  }

  resetFilter() {
    this.filterParameter = '';
    this.resetFilterEvent.emit();
    this.filterParameterChange.emit(this.filterParameter);
  }
}
