import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators'

import {ToolsBoxService} from './tools.service';
import {HttpHeaders} from "@angular/common/http";
import {CallApi, HTTP_COMMAND} from "./callApi";

@Injectable()
export class AuthenticationService {

  constructor(private callAPI: CallApi, private toolsBox: ToolsBoxService) {
  }

  getSalt = (userLogin: string) : Observable<string>  => {
    return this.callAPI.call(HTTP_COMMAND.GET, '/auth/salt/', userLogin)
      .pipe(map(result =>  result.salt));
  }

  /**
   * The Error handling is dealed in the calling Component
   */
  sendCredential = (userlogin: string, password: string, salt: string): Observable<number> => {

    let userParam = "{\"userLogin\":\"" + userlogin + "\",\"userPassword\":\"" + this.toolsBox.convertPassword(password, salt) + "\"}";
    return this.callAPI.call(HTTP_COMMAND.POST, '/auth/authentication/', userParam)
      .pipe(map(response => {
        this.saveJwt(response.jwt, response.userId, response.userLogin, response.profile, response.userAvatar);
        if (response.pwdToBeChanged) {
          return 1;
        } else {
          return 0;
        }
      }));
  }

  saveJwt = (jwt: string, userId: string, userLogin: string, userProfile: string, userAvatar: string) => {
    if (jwt && jwt != "") {
      try {
        this.toolsBox.authenticatedHeader = new HttpHeaders().append('Authorization', 'Bearer ' + jwt);
        this.toolsBox.userProfile = userProfile;
        this.toolsBox.userId = Number(userId);
        //The user_login is stored in sessionStorage. This allow us to forget the information once the browser is closed
        sessionStorage.setItem('user_login', userLogin);
        sessionStorage.setItem('token', jwt);
        sessionStorage.setItem("userId",userId)
        sessionStorage.setItem("userProfile",userProfile)
        this.toolsBox.changeAvatar(userAvatar);
      } catch (error) {
        console.log('Technical Issue. SessionStorage is unreachable');
        throw new Error('SessionStorage is unreachable');
      }
    }
  }
}
