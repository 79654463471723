import {Component, OnInit} from '@angular/core';
import {ToolsBoxService} from "../../Services/tools.service";
import {ScheduledEvent} from "../../Models/scheduled.model";
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { EventService } from '../../Services/event.service';

@Component({
  selector: 'schedule',
  templateUrl: 'schedule.component.html',
  styleUrls: ['schedule.component.css'],
  providers: [DatePipe]
})
export class ScheduleComponent implements OnInit {

  years: number[] = [new Date().getFullYear() - 1, new Date().getFullYear()];

  //Set of mocked characters to be displayed
  scheduledEvents: ScheduledEvent[] = [];


  constructor(private toolsBoxService: ToolsBoxService,
    private eventService: EventService,
    private router: Router,
    private datePipe: DatePipe) {

  }

  ngOnInit() {
    this.toolsBoxService.changeHeaderName(this.displayYears());

    this.toolsBoxService.tellActionIsInProgress(true);
    this.eventService.getSchedule().subscribe(
      (planningEvents) => {
        planningEvents.filter(planningEvent => planningEvent.event.eventType != "OTH").map((item) => {
          const scheduleEvent = new ScheduledEvent();

          scheduleEvent.possibleDate = this.datePipe.transform(item.event.eventStartDate, 'dd/MM/yyyy');
          scheduleEvent.label = item.event.eventDesc;
          scheduleEvent.purpose = item.event.additionalDesc;
          scheduleEvent.eventType = item.event.eventType;
          scheduleEvent.status = item.event.eventStatus;
          scheduleEvent.itzName = item.event.eventUser.userFirstName + " " + item.event.eventUser.userName;
          scheduleEvent.creationUser = item.event.eventCreUsr.userFirstName + " " + item.event.eventCreUsr.userName;

          scheduleEvent.participation = this.calculateParticipation(item.agreeList.length, item.pendingList.length ,item.refuseList.length);

          scheduleEvent.valitatedCount = item.agreeList.length;
          scheduleEvent.refusedCount = item.refuseList.length;

          this.scheduledEvents.push(scheduleEvent);
        })
      },
      (err) => {
        this.toolsBoxService.tellActionIsInProgress(false);
        this.router.navigate(["Error", { errMessage: err.message }]);
      }
    )
  }

  getIcon = (eventType: String) => {
    let result = "";
    switch (eventType) {
      case "TECHZ":
        result = "../../css/images/techz-34.png";
        break;
      case "CASUALZ":
        result = "../../css/images/casualz-34.png";
        break;
      case "CONFZ":
        result = "../../css/images/confz-34.png";
        break;
      case "TRAINZ":
      case "TRAIN":
        result = "../../css/images/trainz-34.png";
        break;
      case "TRAINZ/TECHZ":
        result = "../../css/images/user.png";
        break;

    }
    return result;
  }

  calculateParticipation = (presents: number, pending: number, refused: number) => {
    if (presents + pending + refused == 0) {
      return "0%";
    }
    return Math.round((presents / (presents + pending + refused)) * 100) + "%";
  }

  displayYears () {
    return "Events " + this.years[0] + "/" + this.years[1] + ", provisional calendar";
  }

  isAdmin = () =>  {
    return this.toolsBoxService.isAdmin();
  }

  goBack() {
    return this.router.navigate(["/Dashboard"]);
  }
}
