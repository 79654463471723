import {Component, OnInit, OnChanges, Input, Output, HostListener, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {ToolsBoxService} from '../Services/tools.service';
import {UserService} from '../Services/user.service';
import {CalendarEventHolder} from '../Models/Holders/calendareventholder.component';
import {HTTP_ERROR} from "../Services/callApi";
import { SelectItem } from 'primeng/api/selectitem';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'input-modal',
  styleUrls: ['inputmodal.component.css'],
  templateUrl: 'inputmodal.component.html',
})
export class InputModalComponent implements OnInit, OnChanges {

  @Input('currentEvent') currentEvent: CalendarEventHolder = new CalendarEventHolder();
  @Input('open') userModalOpened: boolean;
  @Input('locked') locked: boolean = false;

  // Output event
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  //Modale components
  modalButtonLabel: String;
  originalEvent: CalendarEventHolder;
  showModal: boolean;
  eventTypeLabels: any;
  modalTitle: String;
  isModalEditable: boolean;
  allDay: boolean = true;
  headerColorClass: String = "slds-modal slds-fade-in-open modal-event-phol";
  //Notification
  showAlert: boolean = true;
  alertType: string;
  displayNotif: boolean = false;
  timeout: number = 5000;
  alertMessage: string;
  startPeriod: string;
  endPeriod: string;
  fr: any;

  today = new Date();

  minDate = new Date(this.today.getFullYear() - 1, 0, 1);
  maxDate = new Date(this.today.getFullYear() + 1, 2, 31);
  endOfCurrentYear = new Date(this.today.getFullYear(), 11, 31);
  datePipe = new DatePipe('en-US');

  showStartDatePicker: boolean = false;
  showEndDatePicker: boolean = false;

  periods: SelectItem[] = [{label: 'AM', value: 'AM'}, {label: 'PM', value: 'PM'}];

  constructor(private userService: UserService, private toolsBoxService: ToolsBoxService,
              private router: Router) {
  }

  @HostListener('keydown.esc', ['$event'])
  close(resultToSend: String) {
    this.userModalOpened = false;
    this.openChange.emit(this.userModalOpened);
    this.toolsBoxService.tellActionIsInProgress(false);
    this.closeModal.next(resultToSend);

    this.showStartDatePicker = false;
    this.showEndDatePicker = false;
  }


  ngOnInit() {
    this.eventTypeLabels = this.toolsBoxService.eventLabels;

    this.currentEvent.isSelected = false;

    this.fr = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',
      clear: 'Clear'
    };
  }

  //Change background color on event type selection change
  eventTypeChange(state: String) {
    if (state == 'OFF') {
      this.headerColorClass = "slds-modal slds-fade-in-open modal-event-off-duty";
      this.currentEvent.eventType = 'OFF';
      if (this.isModalEditable) {
        this.currentEvent.eventDesc = ''//LEGAL HOLIDAY
      }
    } else if (state == 'SICK') {
      this.headerColorClass = "slds-modal slds-fade-in-open modal-event-sick";
      this.currentEvent.eventType = 'SICK';
      if (this.isModalEditable) {
        this.currentEvent.eventDesc = '' //SICKNESS LEAVE
      }
    } else if (state == 'KIDS') {
      this.headerColorClass = "slds-modal slds-fade-in-open modal-event-kids";
      this.currentEvent.eventType = 'KIDS';
      if (this.isModalEditable) {
        this.currentEvent.eventDesc = '' //KIDS SICKNESS LEAVE
      }
    } else if (state == 'HBW') {
      this.headerColorClass = "slds-modal slds-fade-in-open modal-event-hbw";
      this.currentEvent.eventType = 'HBW';
      if (this.isModalEditable) {
        this.currentEvent.eventDesc = '' //HOME BASED WORKING
      }
    } else {
      console.log("Unknown status");
    }
  }


  onOutsideClickStart() {
    if (this.showStartDatePicker) {
      this.showStartDatePicker = false;
    }
  }

  onOutsideClickEnd() {
    if (this.showEndDatePicker) {
      this.showEndDatePicker = false;
    }
  }

  // Called when modal's status changes
  ngOnChanges() {
    if (this.currentEvent && this.currentEvent.eventType) {
      //Set the allDay value
      this.allDay = (this.currentEvent.eventStartPeriod == 'AM' && this.currentEvent.eventEndPeriod == 'PM');

      //Set background color for radio buttons
      if (this.currentEvent.eventType == 'OFF') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-off-duty";
        this.modalTitle = "Off duty event";
      } else if (this.currentEvent.eventType == 'SICK') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-sick";
        this.modalTitle = "Sickness event";
      } else if (this.currentEvent.eventType == 'KIDS') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-kids";
        this.modalTitle = "Kids sickness event";
      } else if (this.currentEvent.eventType == 'HBW') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-hbw";
        this.modalTitle = "Home based working";
      } else if (this.currentEvent.eventType == 'PHOL') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-phol";
        this.modalTitle = "Public holiday event";
      } else if (this.currentEvent.eventType == 'OTH') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-other";
        this.modalTitle = "Special event";
      } else if (this.currentEvent.eventType == 'TRAIN') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-training";
        this.modalTitle = "Training event";
      } else if (this.currentEvent.eventType == 'TRAINZ') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-trainz";
        this.modalTitle = "Train'z event";
      } else if (this.currentEvent.eventType == 'TECHZ') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-techz";
        this.modalTitle = "Tech'z event";
      } else if (this.currentEvent.eventType == 'CONFZ') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-confz";
        this.modalTitle = "Conf'z event";
      } else if (this.currentEvent.eventType == 'CASUALZ') {
        this.headerColorClass = "slds-modal slds-fade-in-open modal-event-casualz";
        this.modalTitle = "Casual'z event";
      } else {
        console.log("Unknown status [" + this.currentEvent.eventType + "]");
      }

      //if existing existing event, we have to back the old one up
      //Lets backup the original Event
      this.originalEvent = new CalendarEventHolder();
      this.originalEvent.eventType = this.currentEvent.eventType;

      this.originalEvent.eventDesc = this.currentEvent.eventDesc;
      this.originalEvent.eventStartDate = this.currentEvent.eventStartDate;
      this.originalEvent.eventStartPeriod = this.currentEvent.eventStartPeriod;
      this.originalEvent.eventEndDate = this.currentEvent.eventEndDate;
      this.originalEvent.eventEndPeriod = this.currentEvent.eventEndPeriod
      this.originalEvent.eventStatus = this.currentEvent.eventStatus;

      this.isModalEditable = false;

      this.modalButtonLabel = "EDIT";
      //Set the description to be displayed
      if (this.currentEvent.eventStatus == "CORRECTED") {
        this.modalButtonLabel = 'RESTORE';
      } else if (this.currentEvent.eventStatus == "PENDING") {
        this.modalButtonLabel = 'CANCEL';
      } else if (this.currentEvent.eventStatus == "DELETED") {
        this.modalButtonLabel = 'RESTORE';
      } else if (this.currentEvent.eventStatus == "NEW") {
        this.modalTitle = 'New Event';
        this.modalButtonLabel = "SAVE";
        this.originalEvent = null;
        this.isModalEditable = true;
      }
    }
  }


  //In case the "All Day" checkbox is switched on, we set the period from AM to PM
  switchAllDayTag() {
    //We need a delay between the click and the following action since the boolean this.allDay was not updated immediately
    setTimeout(() => {
      if (this.allDay) {
        this.currentEvent.eventStartPeriod = 'AM';
        this.currentEvent.eventEndPeriod = 'PM';
      }
    }, 500);
  }

  isUpdateValid = (): boolean => {
    this.timeout = 5000;

    setTimeout(() => {
        this.alertType = null;
        this.alertMessage = null;
    }, this.timeout);

    // Control dates before insert
    let startDateToCheck = new Date(this.currentEvent.eventStartDate);
    let endDateToCheck = new Date(this.currentEvent.eventEndDate);

    if ((this.originalEvent) && (!this.originalEvent.hasChanged(this.currentEvent))) {
      //No change
      this.alertType = 'error';
      this.alertMessage = 'You did not change anything';
    } else if ((startDateToCheck.getTime() == endDateToCheck.getTime()) && (this.currentEvent.eventStartPeriod == 'PM') && (this.currentEvent.eventEndPeriod == 'AM')) {
      //Invalid Period
      this.alertType = 'error';
      this.alertMessage = 'The period is not valid';
    } else if (startDateToCheck.getTime() > endDateToCheck.getTime()) {
      //Invalid Dates
      this.alertType = 'error';
      this.alertMessage = 'The end date can not be set before the start date';
    }
    return !this.alertMessage && !this.alertType;
  }

  // Depending on the action, this.currentEvent will be updated
  manageEvent = (mode: String, eventId: number) => {
    if (mode == 'SAVE') {
      if (this.isUpdateValid()) {
        this.toolsBoxService.tellActionIsInProgress(true);
        //Sending event to be created with a PENDING or CORRECTED status
        return this.userService.sendUserEvent(this.currentEvent).subscribe({
          next : _ => {
            this.close('OK');
          },
          error : (err) => {
            if (err.status === HTTP_ERROR.NOT_ACCEPTABLE) {
              this.alertType = 'error';
              this.alertMessage = err.error;
              this.close('Impossible to override a off day by sick day !');
            } else {
              this.toolsBoxService.tellActionIsInProgress(false);
              this.router.navigate(["Error", {errMessage: err.error}]);
            }
          }
        })
      }
    } else if (mode == 'RESTORE' || mode == 'CANCEL' || mode == 'DELETE') {
      //return mode for displaying confirmation modal
      this.close(mode);
    } else if (mode == 'EDIT') {
      this.isModalEditable = true;
      this.modalButtonLabel = 'SAVE';
      this.modalTitle = "Edit event";
    }

  }

  onStartDateSelect() {
    if (this.currentEvent.eventStartDate > this.currentEvent.eventEndDate) {
      this.currentEvent.eventEndDate = this.currentEvent.eventStartDate;
    }

    this.showStartDatePicker = false;
    setTimeout(() => this.isModalEditable = true, 0);
  }

  onEndDateSelect() {
    if (this.currentEvent.eventEndDate < this.currentEvent.eventStartDate) {
      this.currentEvent.eventStartDate = this.currentEvent.eventEndDate;
    }
    this.showEndDatePicker = false;
    setTimeout(() => this.isModalEditable = true, 0);
  }

  getDescriptionStyle() {
    if (!this.isModalEditable && this.isCurrentTypeValid(this.currentEvent.eventType) && this.currentEvent.eventStatus == 'VALIDATED') {
      // On décalle pour laisser de la place au bouton supprimer
      return '42px';
    } else {
      return '0';
    }
  }

  isCurrentTypeValid(eventTypeFound: string): boolean {
    return (eventTypeFound == 'OFF' || eventTypeFound == 'SICK' || eventTypeFound == 'KIDS' || eventTypeFound == 'HBW')
  }

  redirectToEventInfo = (eventId: number) => {
    this.router.navigate(["Planning/"+eventId]);
  }

  convertToDate(date: string): Date {
    return new Date(date)
  }

  //Check if the current event type is the searched one
  isEventTypeOf(currentEventType: string, searchedType: string, isModalEditable: boolean): boolean {
    return (currentEventType == searchedType || (isModalEditable && (currentEventType == 'OFF' || currentEventType== 'SICK' ||
    currentEventType == 'KIDS' || currentEventType == '' || currentEventType == 'HBW')));
  }
}

