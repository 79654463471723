import {Component, ViewEncapsulation} from '@angular/core';
import { ToolsBoxService } from '../../Services/tools.service';

@Component({
    selector: 'navBarComponent',
    templateUrl: 'navbar.component.html'
})
export class NavBarComponent {

  pageName: String = 'Dashboard';

  constructor(private toolsBox: ToolsBoxService) {
    let $subResult = this.toolsBox.getEmittedValue().subscribe((item: String)  => this.pageName = item);

  }


  isLoggedin() {
        return this.toolsBox.checkUserisLoggedIn();
    }

  isAdmin = () =>  {
    return this.toolsBox.isAdmin();
  }
}

