import { Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'firstNamePipe'
})

export class FirstNamePipe implements PipeTransform {

    transform(userLogin: String):  String {
        let info: String[] = userLogin.split('.');
        return '' + info[0].charAt(0).toUpperCase() + info[0].slice(1);
    }
}
