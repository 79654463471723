import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from "primeng/api";

@Component({
  selector: 'skill-survey',
  templateUrl: 'skill-survey.component.html',
  styleUrls: ['techRadar.component.css']
})
export class SkillSurveyComponent {

  @Input('skills') skills: {
    id: number,
    label: string,
    quadrant: string,
    level: string,
    mayBeInterested: boolean
  } [] = [];

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  skillLevels: SelectItem[] = [
    {label: 'NONE', value: 'NONE'},
    {label: 'NOTIONS', value: 'NOTIONS'},
    {label: 'PROEFFICIENT', value: 'PROEFFICIENT'}
  ];

  constructor() {
  }

  triggerChange = () => {
    this.onChange.emit();
  }
}
