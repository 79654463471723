import { Pipe, PipeTransform} from '@angular/core';
import { Client } from '../Models/client.component';

@Pipe({
    name: 'filterClient'
})

export class FilterClientPipe implements PipeTransform {

    transform(liste : Client[], criteres: String): any {
        if (criteres){
            return liste.filter((items) => {
                return (items.client.toUpperCase().startsWith(criteres.toUpperCase()));

            });
        } else {
            return liste;
        }

    }


}
