import { Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'initialsPipe'
})

export class InitialsPipe implements PipeTransform {
    
    transform(userLogin: String):  String {
        let info: String[] = userLogin.split('.');        
        return '' + info[0].charAt(0).toUpperCase() + info[1].charAt(0).toUpperCase();
    }
}