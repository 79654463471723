import {CalendarEventHolder} from './Holders/calendareventholder.component';

/**
 * This object represents a Event as it is managed by FullCalendar
 */
export class CalendarEvent {
    id: number;
    title: String;
    start: String;
    end: String;
    // className: String = '';
    color: String;
    backgroundColor: String;
    constraint: String;
    socialEventId: number;
    // will make the time show
    allDay: boolean = false;
    className: String[] = [];
    nextDayThreshold: number = 1;
    display: string = 'block';

  constructor() {}

    convertFromHolder = (myHolder: CalendarEventHolder) => {
        this.id = myHolder.eventId;
        //Event description
        if (myHolder.eventDesc) {
            //console.log("CustomDesc")
            this.title = myHolder.eventDesc;
        } else {
           // console.log("DefaultDesc")
            this.title = myHolder.eventType;
        }

        //Event Start/End Date
        this.start = getNewDate(myHolder.eventStartDate);
        this.end = getNewDate(myHolder.eventEndDate);

        this.setTime(myHolder);

        //console.log("Event Desc["+this.title+"] / myHolder.eventType["+myHolder.eventType+"] / Event Start["+this.start+"] -> End["+this.end+"]");
        this.constraint = myHolder.eventType;

        // Event color
        if (myHolder.eventType == 'TRAIN') {
          this.className.push(' event-train');
        } else if (myHolder.eventType == 'OTH' ){
          this.className.push('event-oth');
        } else if (myHolder.eventType == 'SICK') {
          this.className.push(' event-sick');
        } else if (myHolder.eventType == 'KIDS') {
          this.className.push(' event-kids');
        } else if (myHolder.eventType == 'PHOL') {
          this.className.push(' event-phol');
        } else if (myHolder.eventType == 'HBW') {
          this.className.push(' event-hbw');
        } else if (myHolder.eventType == 'OFF') {
            this.className.push(' event-off');
        } else if (myHolder.eventType == 'TECHZ') {
          this.className.push(' event-techz');
        } else if (myHolder.eventType == 'CONFZ') {
          this.className.push(' event-confz');
        } else if (myHolder.eventType == 'TRAINZ') {
          this.className.push(' event-trainz');
        } else if (myHolder.eventType == 'CASUALZ') {
          this.className.push('event-casualz');
        } else if (myHolder.eventType == 'PART_TIME') {
          // PART_TIME events are not displayed the same way
          // We want the whole background to be colored
          this.title = '';
          this.display = 'background';
          this.allDay = true;
          this.backgroundColor = '#FBF6C1';  // PART TIME
        }
        else if (myHolder.eventType == 'PARENTAL_LEAVE') {
          // PARENTAL_LEAVE events are not displayed the same way
          // We want the whole background to be colored
          this.title = '';
          this.display = 'background';
          this.allDay = true;
          this.backgroundColor = '#F9C6F7';  // CONGE PARENTAL
        }

        if (myHolder.eventStatus == 'PENDING') {
          this.className.push('event-pending');
        }
        if (myHolder.eventStatus == 'CORRECTED') {
          this.className.push('event-corrected');
        }
        if (myHolder.eventStatus == 'DEL_REQUESTED') {
          this.className.push('event-deleted');
        }

        this.socialEventId = myHolder.socialEventId;

        // this.eventColor = '#378006';
        // this.eventBackgroundColor = '#378006';
    }

    setTime = (myHolder: CalendarEventHolder) : void => {
      if (myHolder.eventStartPeriod == 'PM') {
        this.start = this.start + 'T12:00:00';
        this.className.push('event-pm');
      } else if (myHolder.eventStartPeriod == 'AW') {
        this.start = this.start + 'T18:00:00';
        this.className.push('event-aw');
      }else {
        this.start = this.start + 'T00:00:00';
      }

      if (myHolder.eventEndPeriod == 'AM') {
        this.end = this.end + 'T12:00:00';
        //console.log('Only morning event, set class')
        this.className.push('event-am');
      } else if (myHolder.eventEndPeriod == 'AW') {
        this.end = this.end + 'T23:59:59';
        this.className.push('event-aw');
      } else  {
        this.end = this.end + 'T17:59:59';
      }
    }
}

function getNewDate(dateToTransform: string): string {
  const dateTransformed = new Date(dateToTransform);
  return new Date(dateTransformed.setDate(dateTransformed.getDate())).toISOString().split('T')[0];
}

