import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    const date = typeof value === 'string' ? this.parseCustomDateString(value) : value;
    return super.transform(date, 'MMMM d, y');
  }

  private parseCustomDateString(value: string): Date | null {
    const parts = value.split('/');

    if (parts.length === 3) {
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);

      return new Date(year, month, day);
    } else {

      return new Date(value);
    }
  }
}
