import { Component, OnInit } from '@angular/core';
import { ToolsBoxService } from '../../Services/tools.service';

@Component({
    selector: 'headerComponent',
    styleUrls: ['header.component.css'],
    templateUrl: 'header.component.html'
})
export class HeaderComponent implements OnInit {
    userName: String;
    AvatarBackground: String;
    userAvatar: String;
    pageName: String = 'Dashboard';

    constructor(private tools: ToolsBoxService) {

    }

    ngOnInit() {
      let $subResult = this.tools.getEmittedValue().subscribe((item: String)  => this.pageName = item);
      let $subResult2 = this.tools.getEmittedAvatarChange().subscribe((item: String)  => this.userAvatar = item);
      this.userName = this.tools.getStoredUserLogin();
      this.userAvatar = this.tools.getStoredUserAvatar();
      //Should get the Name first letter insttead of the userLogin first letter
      this.AvatarBackground = "user-first-initial-" + this.userName.split('.')[1]?.charAt(0).toLowerCase();
    }

    displayLogin(): String {
      return this.userName;
    }
}
