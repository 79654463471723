import { Injectable } from '@angular/core';
import {Observable, EMPTY} from 'rxjs';

import { ToolsBoxService } from './tools.service';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {catchError} from "rxjs/operators";

@Injectable()
export class CallApi {
  constructor(private newHttp: HttpClient, private toolsBox: ToolsBoxService) {
  }

  call = (command: HTTP_COMMAND, endpoint: string, param?: any, customResponseType? :any) : Observable<any> => {

    let header = this.toolsBox.authenticatedHeader.append('Content-Type', 'application/json')
                    .set('Authorization', 'Bearer ' + this.toolsBox.getStoredToken());

    const responseType = customResponseType != null ? 'blob' : null;

    if (command === HTTP_COMMAND.GET) {
      return this.newHttp.get(this.toolsBox.getServerAddress() + endpoint + (param ? param : ""),
                              {headers: header, responseType: responseType}).pipe(
         catchError((err: HttpErrorResponse) =>  {
         this.toolsBox.tellActionIsInProgress(false);
         console.error("A ", err.status + " error occurred on GET to ", endpoint, ": ", err.error);
         if (err.status === 0) {
           // The server may be unreachable
           throw new Error('Technical Issue. The server could not be reach');
         }
         throw err;
      }));
    } else if (command === HTTP_COMMAND.POST) {
      return this.newHttp.post(this.toolsBox.getServerAddress() + endpoint,
        param,
        {headers: header})
        .pipe(catchError((err: HttpErrorResponse) => {
        this.toolsBox.tellActionIsInProgress(false);
        console.error("A ", err.status + " error occurred on POST to ", endpoint, ": ", err.error);

        if (err.status === 0) {
          // The server may be unreachable
          throw new Error('Technical Issue. The server could not be reach');
        }
        throw err;
      }));
    } else if (command === HTTP_COMMAND.PUT) {
      return this.newHttp.put(this.toolsBox.getServerAddress() + endpoint,
        param,
        {headers: header})
        .pipe(catchError((err: HttpErrorResponse) => {
        this.toolsBox.tellActionIsInProgress(false);
        console.error("A ", err.status + "error occurred on PUT to ", endpoint, ": ", err.error);
        if (err.status === 0) {
          // The server may be unreachable
          throw new Error('Technical Issue. The server could not be reach');
        }
        throw err;
      }));
    } else if (command === HTTP_COMMAND.DELETE) {
      return this.newHttp.delete(this.toolsBox.getServerAddress() + endpoint,
        {headers: header})
        .pipe(catchError((err: HttpErrorResponse) => {
        this.toolsBox.tellActionIsInProgress(false);
        console.error("A ", err.status + "error occurred on DELETE to ", endpoint, ": ", err.error);
        if (err.status === 0) {
          // The server may be unreachable
          throw new Error('Technical Issue. The server could not be reach');
        }
        throw err;
      }));

    }else {
      this.toolsBox.tellActionIsInProgress(false);
      return EMPTY;
    }

  }

}


export enum HTTP_ERROR {
  TECHNICAL =500 ,
  UNAUTHORIZED = 401,
  METHOD_NOT_ALLOWED = 405,
  NOT_ACCEPTABLE = 406
}

export enum HTTP_COMMAND {
  GET =1 ,
  POST = 2,
  PUT = 3,
  DELETE = 0
}
