import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective {

  @Output()
  appClickOutside: EventEmitter<void> = new EventEmitter();

  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: PointerEvent) {
    const nativeElement: any = this.elementRef.nativeElement;
    const clickedInside: boolean = nativeElement.contains(event.target);

    // Check if the clicked element is part of the datepicker
    const isDatepickerHeaderClicked: boolean = this.isDatepickerHeaderClicked(event.target);

    if (!clickedInside && !isDatepickerHeaderClicked) {
      this.appClickOutside.emit();
    }
  }

  private isDatepickerHeaderClicked(target: EventTarget | null): boolean {
    return target instanceof Element && Array.from(target.classList).some(className =>
      className.startsWith('p-datepicker') || className.startsWith('p-ripple')
    );
  }
}
