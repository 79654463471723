import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'notification',
    templateUrl: 'notification.component.html'
})
export class NotificationComponent implements OnInit, OnChanges {

    @Input('severity') severity: String;
    @Input('timeout') timeout: number;
    @Input('message') message: String;
    @Output('hideNotification') onClose: EventEmitter<any> = new EventEmitter();

    className: String;
    displayNotif: boolean = false;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges() {
        if (this.severity==='warning') {
            this.className = 'notif notif-warning';
        } else if (this.severity==='info') {
          this.className = 'notif notif-info';
        } else {
            this.className = 'notif notif-error';
        }
        this.displayNotif = true;
        if (this.timeout) {
          this.displayNotif = true;
          setTimeout(() => {
            this.displayNotif = false;
            this.onClose.next(true);
          }, this.timeout);
        }

    }
}
